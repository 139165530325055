<template>
  <div>
    <b-row
      no-gutters
      class="m-0 py-1 px-2 table-actions-bar"
      align-h="between"
      align-v="center"
    >
      <b-col cols="auto">
        <ActiveFiltersPanel
          :filters="{ ...filters, search }"
          :excluded-filters="excludedFilters"
          @on-clear-active-filters="onClearActiveFilters"
        />
      </b-col>
      <b-col cols="auto">
        <b-link
          v-show="!hideImportButton"
          class="text-indigo mr-1"
          data-cy="printer-link"
          @click="$emit('import-button-click')"
        >
          <feather-icon
            v-b-tooltip.hover 
            :title="textImportButton"
            icon="DownloadIcon"
            size="24"
          />
        </b-link>
        <b-link
          v-show="!hidePrinterButton"
          class="text-indigo mr-1"
          data-cy="printer-link"
          @click="$emit('printer-button-click')"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Generar pdf"
            icon="PrinterIcon"
            size="24"
          />
        </b-link>
        <b-link
          v-show="!hideUploadButton"
          class="text-indigo mr-1"
          data-cy="export-link"
          @click="$emit('upload-button-click')"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Exportar"
            icon="UploadIcon"
            size="24"
          />
        </b-link>
        <b-link
          v-show="!hideFilterButton"
          class="text-indigo mr-1"
          data-cy="filter-link"
          @click="$emit('filter-button-click')"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Filtrar"
            icon="FilterIcon"
            size="24"
          />
        </b-link>
        <CollapsableSearchInput
          v-show="!hideSearchButton"
          v-model="search"
          class="pr-1"
        />
        <b-link
          v-show="!hideTotalsButton"
          class="text-indigo text-capitalize text-decoration-none"
          @click="onTotalsButtonClick"
        >
          <u>Ver totales</u>
          <FeatherIcon
            v-if="!totalRowVisible"
            icon="ChevronDownIcon"
            size="19"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
            size="19"
          />
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ActiveFiltersPanel from '@/components/ui/panel/ActiveFiltersPanel.vue'
import CollapsableSearchInput from '@/components/ui/input/CollapsableSearchInput.vue'

export default {
  name: 'TableToolBar',
  components: { CollapsableSearchInput, ActiveFiltersPanel },
  props: {
    totals: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
    excludedFilters: {
      type: Array,
      default: () => [],
    },
    hidePrinterButton: {
      type: Boolean,
      default: false,
    },
    hideUploadButton: {
      type: Boolean,
      default: false,
    },
    hideImportButton: {
      type: Boolean,
      default: true,
    },
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    hideSearchButton: {
      type: Boolean,
      default: false,
    },
    hideTotalsButton: {
      type: Boolean,
      default: false,
    },
    textImportButton: {
      type: String,
      default: 'Importar',
    },
    resourceStore: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalRowVisible: false,
    }
  },
  computed: {
    search: {
      get () {
        return this.$store.getters[`${this.resourceStore}/getSearch`] || ''
      },
      set (search) {
        this.$store.commit(`${this.resourceStore}/setSearch`, search)
        
        this.searchTimeout = setTimeout(() => {
          this.$emit('search')
        }, 500)
      }
    },
  },
  methods: {
    onClearActiveFilters() {
      this.search = null
      this.$emit('on-clear-active-filters')
    },
    onTotalsButtonClick() {
      this.totalRowVisible = !this.totalRowVisible
      this.$emit('totals-button-click', this.totalRowVisible)
    },
  },
}
</script>

<style scoped>
.table-actions-bar {
  min-height: 76px;
}
</style>
