import { render, staticRenderFns } from "./CollapsableSearchInput.vue?vue&type=template&id=996e1c9a&scoped=true&"
import script from "./CollapsableSearchInput.vue?vue&type=script&lang=js&"
export * from "./CollapsableSearchInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996e1c9a",
  null
  
)

export default component.exports