<template>
  <span
    v-show="filtersActive"
    data-cy="active-filters-panel"
  >
    <b-badge
      v-for="(value, index) in filterValues"
      :key="index"
      variant="light-secondary"
      pill
      class="mr-1 font-weight-normal"
    >
      {{ value }}
    </b-badge>
    <a
      class="text-danger"
      data-cy="clear-active-filters-button"
      @click="onClearActiveFilters"
    >
      Borrar filtros
    </a>
  </span>
</template>

<script>

export default {
  name: 'ActiveFiltersPanel',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    excludedFilters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filtersActive() {
      return this.filterValues.length > 0
    },
    filterValues() {
      if (!this.filters) {
        return []
      }

      const computedFilters = {}

      Object.keys(this.filters).forEach(key => {
        if (!this.excludedFilters.includes(key)) {
          computedFilters[key] = this.filters[key]
        }
      })

      const activeFilters = Object.values(computedFilters)
        .filter(filterValue => filterValue && filterValue !== '')
        .flatMap((filterValue) => {
          if(filterValue instanceof Object) {    
            if(Array.isArray(filterValue)) {
              return filterValue.map(({ name }) => name)
            } 
            return filterValue.name
          } 
          
          return filterValue
        });

      return activeFilters
    },
  },
  methods: {
    onClearActiveFilters() {
      this.$emit('on-clear-active-filters')
    },
  },
}
</script>
