<template>
  <b-link>
    <b-row
      class="d-inline-flex"
      align-v="center"
      no-gutters
    >
      <b-col cols="auto">
        <b-link class="text-indigo">
          <feather-icon
            v-b-tooltip.hover 
            title="Buscar"
            icon="SearchIcon"
            size="24"
            data-cy="search-icon"
            @click="showSearchInput  = !showSearchInput"
          />
        </b-link>
      </b-col>
      <b-col
        cols="auto"
        class="ml-1"
      >
        <collapse-transition dimension="width">
          <b-input-group
            v-if="searchInputVisible"
            class="input-group-merge"
          >
            <b-form-input
              v-model="model"
              type="text"
              class="d-inline-block"
              data-cy="search-input"
              :placeholder="placeholder"
            />
          </b-input-group>
        </collapse-transition>
      </b-col>
    </b-row>
  </b-link>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition/dist/collapse-transition'

export default {
  name: 'CollapsableSearchInput',
  components: {
    CollapseTransition,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Búsqueda',
    },
  },
  data () {
    return {
      showSearchInput: false,
    }
  },
  computed: {
    searchInputVisible() {
      return this.value?.length > 0 || this.showSearchInput
    },
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
}
</script>

<style scoped>
</style>
